<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:150px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div v-if="failure">
          <h2 class="mb-1">
            Connection failed
          </h2>
          <b-button
            variant="primary"
            to="/connect-gsheets"
            class="mb-1 btn-sm-block"
          >
            Go back
          </b-button>
        </div>

        <div v-else-if="success && !spreadsheetCreated">
          <h2 class="mb-1">
            Connection successful
          </h2>
          <p class="mb-3">
            Surfe needs to create a new Spreadsheet to store and manage your
            work.<br>
            <b>A new file will be created in your Google Drive.</b>
          </p>

          <b-form
            inline
            class="row justify-content-center m-0 mb-4"
            @submit.prevent
          >
            <b-button
              variant="outline-primary"
              class="mb-1 btn-sm-block"
              type="submit"
              size="lg"
              @click="attachNewSpreadsheet(false)"
            >
              <span v-if="loading">
                <b-spinner />
              </span>
              <span v-else>
                Create a Spreadsheet
              </span>
            </b-button>
          </b-form>
        </div>

        <div v-if="spreadsheetCreated">
          <h2 class="mb-1">
            You're good to go!
          </h2>

          <p class="mt-2 mb-4">
            To continue, download the Surfe extension and login through the
            popup. Also, Access your CRM Spreadsheet from the below button.
          </p>

          <span>
            <b-button
              href="https://chrome.google.com/webstore/detail/surfe/kojhcdejfimplnokhhhekhiapceggamn?crm=GSHEETS"
              variant="primary"
              class="mb-1 btn-sm-block"
              type="submit"
              size="lg"
              target="_blank"
            >
              Download the Surfe extension
            </b-button>

            <b-button
              :href="spreadsheetURL"
              variant="success"
              class="mb-1 ml-2 btn-sm-block"
              type="submit"
              size="lg"
              target="_blank"
            >
              Access your CRM Spreadsheet
            </b-button>
          </span>
          <b-button
            v-b-toggle.create-new-spreadsheet
          >
            <u>More</u>
          </b-button>
          <b-collapse id="create-new-spreadsheet">
            <div class="m-2">
              <b-form
                inline
                class="row justify-content-center m-0 mb-4"
                @submit.prevent
              >
                <b-button
                  variant="outline-primary"
                  class="mb-1 btn-sm-block"
                  type="submit"
                  size="lg"
                  @click="attachNewSpreadsheet(true)"
                >
                  <span v-if="loading">
                    <b-spinner />
                  </span>
                  <span v-else-if="spreadsheetRecreated">
                    Go to spreadsheet
                  </span>
                  <span v-else>
                    Create new Spreadsheet
                  </span>
                </b-button>
              </b-form>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink, BForm, BButton, BImg, BSpinner, BCollapse,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

const { authHeaders } = require('../../utils')

export default {
  components: {
    BLink,
    BForm,
    BButton,
    BImg,
    BSpinner,
    BCollapse,
  },
  data() {
    return {
      success: false,
      failure: false,
      spreadsheetCreated: false,
      spreadsheetRecreated: false,
      spreadsheetURL: '',
      loading: false,
    }
  },
  async mounted() {
    const appLoading = document.getElementById('loading-bg')

    // Show spinner
    if (appLoading) appLoading.style.display = 'block'

    try {
      const { code } = this.$route.query
      const customCode = localStorage.getItem('customCode')
      const url = `${process.env.VUE_APP_CORE_API_URL}/gsheets/callback?code=${code}&customCode=${customCode}`
      const { data } = await axios.post(url, {})
      this.success = true

      // Store access token
      localStorage.setItem('key', data.key)
      localStorage.setItem(`token_${data.key}`, data.token)

      // Get workspace ID (spreadsheet) if any
      if (data.workspaceId) {
        this.spreadsheetCreated = true
        this.spreadsheetURL = `https://docs.google.com/spreadsheets/d/${data.workspaceId}`
      }
    } catch (error) {
      this.failure = true
      console.error(error)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'An error occurred',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }

    // Hide spinner
    if (appLoading) appLoading.style.display = 'none'
  },
  methods: {
    async attachNewSpreadsheet(forceCreate = false) {
      if (this.loading) return
      if (this.spreadsheetRecreated) {
        window.open(this.spreadsheetURL, '_blank', 'noreferrer')
        return
      }

      this.loading = true

      const url = `${process.env.VUE_APP_CORE_API_URL}/auth/gsheets/spreadsheet?forceCreate=${forceCreate}`
      const { data } = await axios.post(url, {}, authHeaders())
      this.spreadsheetURL = `https://docs.google.com/spreadsheets/d/${data.id}`
      this.spreadsheetCreated = true
      this.spreadsheetRecreated = true

      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
